//whites
$white: #ffffff;
$white-light: #f1f1f1;

//blues
$primary-blue: #5085b6;
$secondary-blue: #ddebf7;
$primary-blue-dark: #326492;

//greens
$primary-green: #9fc754;
$secondary-green: #97ac2d;

//greys
$primary-grey: #e2e2e2;
$secondary-grey: #A6A6A6;

//blacks
$black: #000;

$light-blue: #dcebf6;
$primary-red: #DD1f26;
